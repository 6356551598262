// import React, { useRef } from "react";
// import useOnClickOutside from "../../helper/onClickOutside";

// const CustomDrawer = ({ open, setOpen, side = "right", children }) => {
//   const drawerRef = useRef();
//   useOnClickOutside(drawerRef, () => setOpen(false));
//   return (
//     <div
//       ref={drawerRef}
//       id={`dialog-${side}`}
//       className={`relative z-10 w-full md:w-[30%] h-full transition-transform ease-linear delay-300 duration-500 ${
//         open ? "opacity-100" : "opacity-0"
//       }`}
//       aria-labelledby="slide-over"
//       role="dialog"
//       aria-modal="true"
//       onClick={() => setOpen(!open)}
//     >
//       {/* <div
//         className={`fixed inset-0 bg-black bg-opacity-50 transition-all ${
//           open
//             ? "opacity-100 duration-500 ease-in-out visible"
//             : "opacity-0 duration-500 ease-in-out invisible"
//         } `}
//       ></div> */}
//       <div className={`${open ? "fixed inset-0 overflow-hidden" : ""}`}>
//         <div className="absolute inset-0 overflow-hidden">
//           <div
//             className={`fixed w-[70%] md:w-[40%] lg:w-[30%] xl:w-[15%] max-w-full inset-y-0 right-0 transform transition-transform duration-500 ${
//               open ? "translate-x-0" : "translate-x-full"
//             }`}
//           >
//             <div
//               className={`pointer-events-auto relative w-full h-full transform transition-transform ease-in-out duration-500 ${
//                 open ? "translate-x-0" : "translate-x-full"
//               }`}
//               onClick={(event) => {
//                 event.preventDefault();
//                 event.stopPropagation();
//               }}
//             >
//               <div
//                 className={
//                   "flex flex-col h-full overflow-y-scroll w-full p-3 shadow-xl bg-white rounded-lg"
//                 }
//               >
//                 <div className="flex justify-between items-center mb-4">
//                   <span className="text-lg font-semibold">Filter</span>
//                   <div
//                     onClick={() => setOpen(false)}
//                     className=" w-7 h-7 rounded-full bg-[#E7EFF5] text-custom-blue flex justify-center items-center cursor-pointer"
//                   >
//                     X
//                   </div>
//                 </div>
//                 {children}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CustomDrawer;

import React, { useRef } from "react";
import useOnClickOutside from "../../helper/onClickOutside";

const CustomDrawer = ({ open, setOpen, side = "right", children }) => {
  const drawerRef = useRef();
  useOnClickOutside(drawerRef, () => setOpen(false));

  return (
    <div
      ref={drawerRef}
      id={`dialog-${side}`}
      className={`relative z-10 w-full md:w-[30%] h-full transition-transform ease-linear delay-300 duration-500 ${
        open ? "opacity-100" : "opacity-0"
      }`}
      aria-labelledby="slide-over"
      role="dialog"
      aria-modal="true"
    >
      <div className={`${open ? "fixed inset-0 overflow-hidden" : ""}`}>
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={`pointer-events-none fixed w-[70%] md:w-[40%] lg:w-[30%] xl:w-[15%] max-w-full inset-y-0 right-0`}
          >
            <div
              className={`pointer-events-auto relative w-full h-full transform transition-transform ease-in-out duration-500 ${
                open ? "translate-x-0" : "translate-x-full"
              }`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div className="flex flex-col h-full overflow-y-scroll w-full p-3 shadow-xl bg-white rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-semibold">Filter</span>
                  <div
                    onClick={() => setOpen(false)}
                    className="w-7 h-7 rounded-full bg-[#E7EFF5] text-custom-blue flex justify-center items-center cursor-pointer"
                  >
                    X
                  </div>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDrawer;
