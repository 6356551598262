import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { employeeEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchAllEmployees = createAsyncThunk(
  "fetchAllEmployees",
  async ({ token, department, designation, currentPage, itemsPerPage }) => {
    const queryParams = {};

    if (department) queryParams.department = department;
    if (designation) queryParams.designation = designation;
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }

    const response = await apiConnector(
      "GET",
      employeeEndPoints.EMPLOYEE_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );
    return response.data;
  }
);

// Create a slice
const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employeeData: [],
    employeeLoading: false,
    employeeError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployees.pending, (state) => {
        state.employeeLoading = true;
        state.employeeData = null;
        state.employeeError = null;
      })
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.employeeData = action.payload;
        state.employeeLoading = false;
        state.employeeError = null;
      })
      .addCase(fetchAllEmployees.rejected, (state, action) => {
        state.employeeError = action.error.message;
        state.employeeLoading = false;
        state.employeeData = null;
      });
  },
});

export default employeeSlice.reducer;
