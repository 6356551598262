import React from "react";

const CustomButton = ({ title,disabled, buttonType, classname, onClick = () => {} }) => {
  return (
    <button type={buttonType} className={classname} onClick={onClick} disabled={disabled}>
      {title}
    </button>
  );
};

export default CustomButton;
