import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  CustomDrawer,
  CustomSwitch,
  Pagination,
  TableHeader,
} from "../common";
import { IoMdSearch } from "react-icons/io";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../helper/onClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import Skeleton from "react-loading-skeleton";
import { Roles } from "../../constant/Constant";
import { toast } from "react-toastify";
import { fetchDepartment } from "../../redux/slice/departmentSlice";
import { fetchDesignation } from "../../redux/slice/designationSlice";
import { dateFormatter } from "../../helper/formatDate";
import { GrView } from "react-icons/gr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Employees = () => {
  const ref = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, user } = useSelector((state) => state.auth),
    { employeeLoading } = useSelector((state) => state.employee),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1),
    [employeeCount, SetEmployeeCount] = useState(0),
    [searchString, setSearchString] = useState(""),
    [departments, setDepartments] = useState([]),
    [designations, setDesignations] = useState([]),
    [employees, setEmployees] = useState([]),
    [tableData, setTableData] = useState([]),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const [employeeStatus, setEmployeeStatus] = useState(false);
  const [filterDepartment, setFilterDepartment] = useState("");
  const [filterDesignation, setFilterDesignation] = useState("");
  const TableHeads = [
    "Employee ID",
    "Name",
    "Email",
    "Contact Number",
    "Department",
    "Designation",
    "Last Login",
    "Status",
    "Actions",
  ];

  useOnClickOutside(ref, () => setOpenDropdownIndex(null));

  const fetchEmployees = async (department = "", designation = "") => {
    try {
      const response = await dispatch(
        fetchAllEmployees({
          token,
          department,
          designation,
          currentPage,
          itemsPerPage,
        })
      ).unwrap();

      if (response?.data) {
        setEmployees(response.data.employees);
        setTableData(response.data.employees);
        SetEmployeeCount(response.data.employee_count);
      }
      setOpenFilterDrawer(false);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [currentPage, itemsPerPage]);

  const filterEmployee = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterCompanies = employees?.filter(
        (item) =>
          item?.user_name?.toLowerCase().includes(val.toLowerCase()) ||
          item?.email?.toLowerCase() === val.toLowerCase() ||
          item?.mobile_number?.toString().includes(val.toLowerCase()) ||
          item?.department?.department_name
            ?.toLowerCase()
            .includes(val.toLowerCase()) ||
          item?.designation?.designation
            ?.toLowerCase()
            .includes(val.toLowerCase())
      );
      setTableData(filterCompanies);
    } else {
      setTableData(employees);
    }
  };
  const pageCount = Math.ceil(employeeCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };

  const fetchDepartments = async () => {
    try {
      const response = await dispatch(fetchDepartment({ token })).unwrap();

      if (response?.data) {
        setDepartments(response?.data?.departments);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const fetchDesignations = async () => {
    try {
      const response = await dispatch(fetchDesignation({ token })).unwrap();

      if (response?.data) {
        setDesignations(response.data.designation);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    fetchDepartments();
    fetchDesignations();
  }, []);

  const departmentOptions = departments?.map((department) => ({
    name: department?.department_name,
    value: department?._id,
  }));

  const designationOptions = designations?.map((designation) => ({
    name: designation?.designation,
    value: designation?._id,
  }));
  const handleDepartmentFilterHandler = (e) => {
    const department = e?.target?.value;
    setFilterDepartment(department);
    fetchEmployees(department);
  };
  const handleDesignationFilterHandler = (e) => {
    const designation = e?.target?.value;
    setFilterDesignation(designation);
    fetchEmployees("", designation);
  };
  const StatusHandler = (status) => {
    setEmployeeStatus(status);
  };

  return (
    <div className="w-full h-full bg-custom-white rounded-md">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold">Employee List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterEmployee(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            classname="bg-custom-white text-slate-400 p-2 rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Employee</span>
                </div>
              }
              buttonType="button"
              onClick={() => navigate("/employees/create")}
              classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
            />
          )}
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border rounded-lg bg-custom-white overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {employeeLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tableData && tableData?.length > 0 ? (
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <tr
                    key={item?._id}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="p-2 text-sm">{item?.emp_id}</td>
                    <td className="p-2 text-sm">
                      <div
                        onClick={() =>
                          navigate("/employees/view", {
                            state: { id: item?._id },
                          })
                        }
                        className="flex justify-start items-center gap-4 cursor-pointer"
                      >
                        <img
                          src={
                            item?.avatar
                              ? `${BASE_URL}/${item?.avatar}`
                              : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
                          }
                          alt={item?.user_name}
                          className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                        />
                        <span>{item?.user_name}</span>
                      </div>
                    </td>
                    <td className="p-2 text-sm">{item?.email}</td>
                    <td className="p-2 text-sm">{item?.mobile_number}</td>
                    <td className="p-2 text-sm">
                      {item?.roleId === Roles?.admin
                        ? "Admin"
                        : item?.department?.department_name ?? "-"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.roleId === Roles?.admin
                        ? "Admin"
                        : item?.designation?.designation ?? "-"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.last_login
                        ? dateFormatter(item?.last_login, {
                            format: "MMM D, YYYY hh:mm A",
                            includeTimeForToday: true,
                          })
                        : "--"}
                    </td>
                    <td>
                      <div className="flex items-center text-sm text-start gap-2">
                        <div
                          className={`w-3 h-3  rounded-full ${
                            item?.isActive ? "bg-green-500" : "bg-red-500"
                          }`}
                        />
                        <span>{item?.isActive ? "Active " : "Inactive"}</span>
                      </div>
                    </td>
                    <td className="p-2 flex justify-center items-center gap-2 cursor-pointer">
                      <div
                        onClick={() =>
                          navigate("/employees/view", {
                            state: { id: item?._id },
                          })
                        }
                        className="p-1 border border-custom-blue rounded-md cursor-pointer"
                      >
                        <GrView className="text-custom-blue" />
                      </div>
                      <div
                        onClick={() => {}}
                        className="p-1 border border-custom-blue rounded-md cursor-pointer"
                      >
                        <MdOutlineEdit className="text-custom-blue" />
                      </div>
                      {/* {openDropdownIndex === index && (
                        <CustomActionDropDown
                          ref={ref}
                          Actions={[
                            {
                              id: 1,
                              name: "View",
                              onClick: () =>
                                navigate("/employees/view", {
                                  state: { id: item?._id },
                                }),
                            },
                            {
                              id: 2,
                              name: "Edit",
                              onClick: () => {},
                            },
                            // {
                            //   id: 3,
                            //   name: "Delete",
                            //   onClick: () => {},
                            // },
                          ]}
                        />
                      )} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm"
                >
                  Data Not Found
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                Showing{" "}
                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}{" "}
                to {(currentPage - 1) * itemsPerPage + employees?.length} from{" "}
                {employeeCount} entries
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {openFilterDrawer && (
        <CustomDrawer open={openFilterDrawer} setOpen={setOpenFilterDrawer}>
          <div className="my-4">
            <label className="text-custom-black font-semibold block">
              Department
            </label>
            <select
              name="department"
              placeholder="Select Department"
              className="border w-full rounded-md p-2 outline-none focus:border-custom-blue"
              value={filterDepartment}
              onChange={handleDepartmentFilterHandler}
            >
              <option value={""}>Select</option>
              {departmentOptions &&
                departmentOptions?.map((item) => (
                  <option key={item?.value} value={item?.value}>
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="my-4">
            <label className="text-custom-black font-semibold block">
              Designation
            </label>
            <select
              name="designation"
              placeholder="Select Designation"
              className="border w-full rounded-md p-2 outline-none focus:border-custom-blue"
              onChange={handleDesignationFilterHandler}
              value={filterDesignation}
            >
              <option value={""}>Select</option>
              {designationOptions &&
                designationOptions?.map((item) => (
                  <option key={item?.value} value={item?.value}>
                    {item?.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full absolute bottom-0 pb-2">
            <div className="w-[85%] h-[1px] bg-slate-200 my-2" />
            <CustomButton
              title={"Clear"}
              onClick={() => {
                setFilterDepartment("");
                fetchEmployees();
                setOpenFilterDrawer(false);
              }}
              buttonType="submit"
              classname={"border px-4 py-2 rounded-md"}
            />
          </div>
        </CustomDrawer>
      )}
    </div>
  );
};

export default Employees;
