import React from "react";
import Select from "react-dropdown-select";
import { Controller } from "react-hook-form";

const CustomSelect = ({
  name = "",
  label = "",
  control,
  required = false,
  error,
  placeholder = "Select...",
  labelClassName = "text-custom-black",
  classname = "",
  multiple = false,
  searchable = true,
  options,
  isEditable = false,
}) => {
  return (
    <>
      <label
        htmlFor={name}
        className={`block font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            labelField="name"
            valueField="value"
            multi={multiple}
            searchable={searchable}
            searchBy="name"
            options={options}
            placeholder={placeholder}
            values={
              multiple
                ? options?.filter((option) => value?.includes(option?.value))
                : options?.find((option) => option?.value === value)
                ? [options?.find((option) => option?.value === value)]
                : []
            }
            onChange={(selected) => {
              if (multiple) {
                const selectedValues = selected?.map((item) => item?.value);
                onChange(selectedValues);
              } else {
                onChange(selected[0]?.value);
              }
            }}
            required={required}
            disabled={isEditable}
            color="#2780C1"
            className={`${classname} text-sm mt-2 border border-slate-300 ${
              isEditable ? "bg-gray-100" : ""
            }`}
            style={{
              border: "1px solid #E5E7EB",
              padding: "8px",
              borderRadius: "6px",
              boxShadow: "none",
              background: isEditable ? "#F3F4F6" : "",
              pointerEvents: isEditable ? "none" : "auto",
              opacity: 1,
            }}
          />
        )}
      />
      {error && <p className="text-sm text-red-500">{error}</p>}
    </>
  );
};

export default CustomSelect;
