import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  CustomModal,
  Pagination,
  TableHeader,
} from "../../common";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../../helper/onClickOutside";
import CreateDepartment from "./CreateDepartment";
import { dateFormatter } from "../../../helper/formatDate";
import { fetchDepartment } from "../../../redux/slice/departmentSlice";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { RiDeleteBinLine } from "react-icons/ri";
import { departmentEndPoints } from "../../../networking/Endpoints";
import { setLoading } from "../../../redux/slice/authSlice";
import { apiConnector } from "../../../networking/ApiConnector";

const Department = () => {
  const ref = useRef(),
    dispatch = useDispatch(),
    { token } = useSelector((state) => state.auth),
    { departmentLoading } = useSelector((state) => state.department),
    [singleDepartmentDetails, setSingleDepartmentDetails] = useState(null),
    [showDepartmentFormModal, setShowDepartmentFormModal] = useState(false),
    [departments, setDepartments] = useState([]),
    [departmentTableData, setDepartmentTableData] = useState([]),
    [searchString, setSearchString] = useState(""),
    [departmentCount, setDepartmentCount] = useState(0),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [
      showDeleteDepartmentConfirmationModal,
      setShowDeleteDepartmentConfirmationModal,
    ] = useState(false),
    [currentPage, setCurrentPage] = useState(1);

  const TableHeads = [
    "S.No",
    "Department's Name",
    "Created At",
    "Last Updated At",
    "Status",
    "Actions",
  ];

  useOnClickOutside(ref, () => setOpenDropdownIndex(null));

  const fetchDepartments = async () => {
    try {
      const response = await dispatch(
        fetchDepartment({ token, currentPage, itemsPerPage })
      ).unwrap();

      if (response?.data) {
        setDepartments(response.data.departments);
        setDepartmentTableData(response.data.departments);
        setDepartmentCount(response.data.department_count);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [currentPage, itemsPerPage]);

  const filterDepartment = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredDepartments = departments?.filter(
        (item) =>
          item?.department_name.toLowerCase().includes(val.toLowerCase()) ||
          dateFormatter(item?.createdAt)
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          dateFormatter(item?.updatedAt)
            .toLowerCase()
            .includes(val.toLowerCase())
      );
      setDepartmentTableData(filteredDepartments);
    } else {
      setDepartmentTableData(departments);
    }
  };

  const pageCount = Math.ceil(departmentCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
    fetchDepartment();
  };
  const deleteDepartment = async (department_id) => {
    dispatch(setLoading(true));
    try {
      const department_response = await apiConnector(
        "DELETE",
        `${departmentEndPoints?.DEPARTMENT_API}/${department_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(department_response?.data?.message);
      if (fetchDepartments) {
        fetchDepartments();
      }
      setShowDeleteDepartmentConfirmationModal(false);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="w-full">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">Department List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filterDepartment(e)}
              value={searchString}
              className="border px-6 py-2 outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={<MdOutlineFilterAlt size={22} />}
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 rounded-md border "
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Department</span>
              </div>
            }
            buttonType="button"
            onClick={() =>
              setShowDepartmentFormModal(
                (showDepartmentFormModal) => !showDepartmentFormModal
              )
            }
            classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {departmentLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : departmentTableData && departmentTableData?.length > 0 ? (
            <tbody>
              {departmentTableData?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="p-2 text-sm font-medium">{index + 1}</td>
                    <td className="p-2 text-sm">{item?.department_name}</td>
                    <td className="p-2 text-sm">
                      {item?.createdAt
                        ? dateFormatter(item?.createdAt, {
                            format: "MMM DD, YYYY hh:mm A",
                          })
                        : "--"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.createdAt !== item?.updatedAt
                        ? dateFormatter(item?.updatedAt, {
                            format: "MMM DD, YYYY hh:mm A",
                          })
                        : "-"}
                    </td>
                    <td>
                      <div className="flex items-center text-sm text-start gap-2">
                        <div
                          className={`w-3 h-3  rounded-full ${
                            item?.isActive ? "bg-green-500" : "bg-red-500"
                          }`}
                        />
                        <span>{item?.isActive ? "Active " : "Inactive"}</span>
                      </div>
                    </td>
                    <td className="p-2 flex justify-center items-center ">
                      <div
                        onClick={() => {
                          // setOpenDropdownIndex(
                          //   openDropdownIndex === index ? null : index
                          // );
                          setSingleDepartmentDetails(item);
                          setShowDepartmentFormModal(true);
                        }}
                        className="p-1 border border-custom-blue rounded-md cursor-pointer"
                      >
                        <MdOutlineEdit className="text-custom-blue" />
                      </div>
                      {/* <div
                        onClick={() => {
                          setOpenDropdownIndex(
                            openDropdownIndex === index ? null : index
                          );
                        }}
                        className="p-1 border rounded-md "
                      >
                        <HiOutlineDotsVertical />
                      </div>

                      {openDropdownIndex === index && (
                        <CustomActionDropDown
                          ref={ref}
                          Actions={[
                            {
                              id: 1,
                              name: "Edit",
                              onClick: () => {
                                setSingleDepartmentDetails(item);
                                setShowDepartmentFormModal(true);
                              },
                            },
                            {
                              id: 2,
                              name: "Delete",
                              onClick: () => {
                                setSingleDepartmentDetails(item);
                                setShowDeleteDepartmentConfirmationModal(
                                  (showDeleteDepartmentConfirmationModal) =>
                                    !showDeleteDepartmentConfirmationModal
                                );
                              },
                            },
                          ]}
                        />
                      )}
                      {showDeleteDepartmentConfirmationModal && (
                        <CustomModal
                          isOpen={showDeleteDepartmentConfirmationModal}
                          onClose={() =>
                            setShowDeleteDepartmentConfirmationModal(false)
                          }
                        >
                          <div className="text-center text-custom-black">
                            <h2 className="text-2xl font-bold my-4">Delete?</h2>
                          </div>
                          <div className="w-20 h-20 bg-[#FFECEC] p-2 rounded-full flex justify-center items-center">
                            <div className="bg-[#FB0F0F] rounded-full w-16 h-16 flex justify-center items-center">
                              <RiDeleteBinLine
                                size={32}
                                className="text-custom-white"
                              />
                            </div>
                          </div>
                          <div className="text-center text-[#747474]">
                            <h2 className="font-medium my-4">
                              Are you sure you want to delete it?
                            </h2>
                          </div>

                          <div className="flex justify-end items-center gap-5 mt-5">
                            <CustomButton
                              title={"Cancel"}
                              onClick={() =>
                                setShowDeleteDepartmentConfirmationModal(false)
                              }
                              buttonType={"button"}
                              classname={
                                "px-2 py-1 bg-custom-white border rounded-md "
                              }
                            />
                            <CustomButton
                              title={"Delete"}
                              onClick={() =>
                                deleteDepartment(singleDepartmentDetails?._id)
                              }
                              buttonType={"button"}
                              classname={
                                "bg-[#FB0F0F] text-custom-white px-2 py-1 rounded-md"
                              }
                            />
                          </div>
                        </CustomModal>
                      )} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm"
                >
                  Data Not Found
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                Showing{" "}
                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}
                to {(currentPage - 1) * itemsPerPage +
                  departments?.length} from {departmentCount} entries
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {showDepartmentFormModal && (
        <CreateDepartment
          departmentDetails={singleDepartmentDetails}
          setSingleDepartmentDetails={setSingleDepartmentDetails}
          showDepartmentFormModal={showDepartmentFormModal}
          setShowDepartmentFormModal={setShowDepartmentFormModal}
          getDepartments={fetchDepartments}
        />
      )}
    </div>
  );
};

export default Department;
