import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { projectEndPoints } from "../../networking/Endpoints";
import { ApiError } from "../../helper/ApiError";
import { toast } from "react-toastify";
import { CommonTaskTable } from "../task";
import { getAllTeamTask } from "../../redux/slice/taskSlice";
import ProjectDetails from "./ProjectDetails";
import ProjectMembers from "./ProjectMembers";
import { CustomButton } from "../common";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ProjectOverview from "./ProjectOverview";

const ProjectView = () => {
  const { token, loading } = useSelector((state) => state.auth);
  const [projectActiveTab, setProjectActiveTab] = useState("overview");
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location?.state?.id;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [tasksTableData, setTasksTableData] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const handleTabClick = (tab) => {
    setProjectActiveTab(tab);
  };
  const fetchProjectDetails = async () => {
    dispatch(setLoading(true));
    try {
      const project_response = await apiConnector(
        "GET",
        `${projectEndPoints?.PROJECT_API}/${projectId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setProject(project_response?.data?.projectDetail);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchTeamTasks = async () => {
    try {
      const response = await dispatch(
        getAllTeamTask({ token, currentPage, itemsPerPage, projectId })
      ).unwrap();
      if (response?.data) {
        setTasks(response?.data?.tasks);
        setTasksTableData(response?.data?.tasks);
        setTaskCount(response?.data?.task_count);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchProjectDetails();
  }, [projectId]);

  useEffect(() => {
    fetchTeamTasks();
  }, [projectId, currentPage, itemsPerPage]);

  return (
    <div className="w-full h-full bg-custom-white space-y-2 ">
      <section className="relative w-full bg-[#E6F0F6] flex justify-start gap-2 md:gap-10 overflow-x-auto font-semibold">
        <div className="px-4 py-2">
          <CustomButton
            title={<MdOutlineKeyboardBackspace size={24} />}
            buttonType={"button"}
            onClick={() => navigate(-1)}
            classname={`w-8 h-8 rounded-full flex justify-center items-center bg-custom-blue text-custom-white`}
          />
        </div>
        <h2
          onClick={() => handleTabClick("overview")}
          className={`cursor-pointer px-4 py-2 ${
            projectActiveTab === "overview"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Overview
        </h2>
        <h2
          onClick={() => handleTabClick("project_details")}
          className={`cursor-pointer px-4 py-2 ${
            projectActiveTab === "project_details"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Project Details
        </h2>
        <h2
          onClick={() => handleTabClick("team_task")}
          className={`cursor-pointer px-4 py-2 ${
            projectActiveTab === "team_task"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Team Task
        </h2>
        <h2
          onClick={() => handleTabClick("members")}
          className={`cursor-pointer px-4 py-2 ${
            projectActiveTab === "members"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Members
        </h2>
        {/* <h2
          onClick={() => handleTabClick("timeline")}
          className={`cursor-pointer px-4 py-2 ${
            projectActiveTab === "timeline"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Timeline
        </h2> */}
      </section>
      {projectActiveTab === "overview" && (
        <ProjectOverview project_id={projectId} />
      )}
      {projectActiveTab === "project_details" && (
        <ProjectDetails
          project={project}
          fetchProjectDetails={fetchProjectDetails}
        />
      )}
      {projectActiveTab === "team_task" && (
        <CommonTaskTable
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
          tasks={tasks}
          setTasks={setTasks}
          tasksTableData={tasksTableData}
          setTasksTableData={setTasksTableData}
          taskCount={taskCount}
          defaultProject={project?._id}
          fetchAllTasks={fetchTeamTasks}
        />
      )}
      {projectActiveTab === "members" && <ProjectMembers project={project} />}
      {/* {projectActiveTab === "timeline" && "IV"} */}
    </div>
  );
};

export default ProjectView;
