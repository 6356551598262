import React, { useEffect, useState } from "react";
import ProjectCard from "./ProjectCard";
import { CustomButton, Pagination } from "../common";
import { MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { ApiError } from "../../helper/ApiError";
import { toast } from "react-toastify";
import { getAllProject } from "../../redux/slice/projectSlice";
import { Roles } from "../../constant/Constant";
import { AnimatePresence, motion } from "framer-motion";

const Project = () => {
  const navigate = useNavigate(),
    [searchString, setSearchString] = useState(""),
    dispatch = useDispatch(),
    { token, user } = useSelector((state) => state.auth),
    { projectLoading } = useSelector((state) => state.project),
    [isEditMode, setIsEditMode] = useState(false),
    [projects, setProjects] = useState([]),
    [tableData, setTableData] = useState([]),
    [projectCount, setProjectCount] = useState(0),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1),
    [hoveredIndex, setHoveredIndex] = useState(null);
  const fetchProjects = async () => {
    try {
      const response = await dispatch(
        getAllProject({ token, currentPage, itemsPerPage })
      ).unwrap();

      if (response?.data) {
        setProjects(response?.data?.projects);
        setTableData(response?.data?.projects);
        setProjectCount(response?.data?.project_count);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentPage, itemsPerPage]);

  const pageCount = Math.ceil(projectCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
    fetchProjects();
  };

  const filterProject = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredProject = projects?.filter(
        (item) =>
          item?.project_name.toLowerCase().includes(val.toLowerCase()) ||
          item?.projectStatus.toLowerCase().includes(val.toLowerCase())
      );
      setTableData(filteredProject);
    } else {
      setTableData(projects);
    }
  };

  return (
    <div className="w-full h-full bg-custom-white rounded-md p-5 space-y-2">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-md">
        <h1 className="font-semibold">Project List</h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterProject(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={<MdOutlineFilterAlt size={22} />}
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Project</span>
                </div>
              }
              buttonType="button"
              onClick={() => navigate("/project/create")}
              classname="bg-gradient-custom text-custom-white px-5 py-2 rounded-md"
            />
          )}
        </div>
      </section>
      {projectLoading ? (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 overflow-auto">
          {[1, 2, 3, 4, 5, 6]?.map((index) => (
            <div
              key={index}
              className="w-full border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer flex flex-col"
            >
              <div className="flex justify-between items-center space-x-2">
                <div className="w-40">
                  <Skeleton width={"70"} />
                </div>
                <div className="w-[15px] h-[15px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
              </div>
              <div>
                <Skeleton count={3} />
              </div>
              <div className="flex space-x-2">
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : tableData && tableData?.length > 0 ? (
        <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
          {tableData?.map((project, index) => (
            <div
              key={project?._id}
              className="relative group  block p-2 h-full w-full cursor-pointer"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <AnimatePresence>
                {hoveredIndex === index && (
                  <motion.span
                    className="absolute inset-0 h-full w-full bg-slate-200/[0.3] dark:bg-slate-200/[0.4] block rounded-xl"
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { duration: 0.15 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15, delay: 0.2 },
                    }}
                  />
                )}
              </AnimatePresence>
              <ProjectCard
                // key={index}
                _id={project?._id}
                project_name={project?.project_name}
                project_manager={project?.project_manager}
                department={project?.department}
                project_id={project?.project_id}
                project_description={project?.project_description}
                start_date={project?.project_start_date}
                end_date={project?.project_deadline}
                assignees={project?.members}
                status={project?.projectStatus}
                onClick={() =>
                  navigate("/project/view", { state: { id: project?._id } })
                }
                setIsEditMode={setIsEditMode}
                fetchAllProjects={fetchProjects}
              />
            </div>
          ))}
        </section>
      ) : (
        <p className="p-2 font-semibold text-sm">Data Not Found</p>
      )}
      <section className="flex sm:flex-row flex-col justify-between items-center">
        <span className="text-slate-400 text-sm px-2 py-4">
          Showing {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}{" "}
          to {(currentPage - 1) * itemsPerPage + projects?.length} from{" "}
          {projectCount} entries
        </span>

        <Pagination
          handlePageClick={handlePageClick}
          pageRangeDisplayed={itemsPerPage}
          pageCount={pageCount}
          name={"pageSize"}
          onChange={(e) => setItemsPerPage(e?.target?.value)}
        />
      </section>
    </div>
  );
};

export default Project;
