import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/common/Header";
import { Home, Login } from "./pages";
import Dashboard from "./components/dashboard/Dashboard";
import {
  CreateCompanyForm,
  Company,
  CompanyView,
} from "./components/super_admin";
import { useSelector } from "react-redux";
import { Error, PrivateRoute, Profile } from "./components/common";
import {
  CompanyProfile,
  Department,
  Designation,
  OfficeLocation,
} from "./components/admin";
import { Roles } from "./constant/Constant";
import { Project, ProjectForm, ProjectView } from "./components/project";
import { useState } from "react";
import { Employees, EmployeesForm, EmployeeView } from "./components/employee";
import Calender from "./components/project_manager/Calender";
import Report from "./components/project_manager/Report";
import Timesheets from "./components/project_manager/Timesheets";
import { PersonalTask, TaskForm, TaskView } from "./components/task";
import ProjectOverview from "./components/project/ProjectOverview";
import LandingPage from "./pages/LandingPage";

function App() {
  const { user } = useSelector((state) => state.auth);
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className="w-[100vw] h-[100vh]">
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route
          element={
            <PrivateRoute>
              <Header
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
              />
              <Home showSidebar={showSidebar} />
            </PrivateRoute>
          }
        >
          <Route exact path="/dashboard" element={<Dashboard />} />

          {/* super_admin routes */}
          {user?.roleId === Roles?.super_admin && (
            <>
              <Route exact path="/company" element={<Company />} />
              <Route
                exact
                path="/company/create"
                element={<CreateCompanyForm />}
              />
              <Route exact path="/company-view" element={<CompanyView />} />
            </>
          )}
          {/* admin routes */}
          {user?.roleId === Roles?.admin && (
            <>
              <Route exact path="/department" element={<Department />} />
              <Route exact path="/designation" element={<Designation />} />
              <Route
                exact
                path="/office_location"
                element={<OfficeLocation />}
              />
              <Route
                exact
                path="/company-profile"
                element={<CompanyProfile />}
              />
            </>
          )}

          <Route exact path="/task" element={<PersonalTask />} />
          <Route exact path="/task/create" element={<TaskForm />} />
          <Route exact path="/task/view" element={<TaskView />} />
          <Route exact path="/employees" element={<Employees />} />
          <Route exact path="/employees/create" element={<EmployeesForm />} />
          <Route exact path="/employees/view" element={<EmployeeView />} />
          <Route exact path="/project" element={<Project />} />
          <Route exact path="/project/create" element={<ProjectForm />} />
          <Route exact path="/project/view" element={<ProjectView />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/calender" element={<Calender />} />
          <Route exact path="/report" element={<Report />} />
          <Route exact path="/timesheets" element={<Timesheets />} />
          <Route exact path="/overview" element={<ProjectOverview />} />
          {/* <Route exact path="/create_task_form" element={<CreateTask />} /> */}
        </Route>
        <Route exact path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
