import React from "react";

function Herosection() {
  return (
    <div className="bg-[#E9F6FB] xl:px-0 lg:px-0 px-[20px] relative">
      <div className="2xl:pt-[120px] xl:pt-[120px] lg:pt-[120px] md:pt-[60px] pt-[40px] 2xl:pb-[160px] xl:pb-[160px] lg:pb-[160px] md:pb-[60px] pb-[40px]">
        <div className="container mx-auto">
          <div className="pb-[40px]">
            <span className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] text-[11px] bg-[#DDF1FF] p-[10px] rounded-[10px] ">
              No subscription. No annual fees. No credit card required.
            </span>
          </div>
          <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-4">
            <div>
              <div className="max-w-[680px]">
                <div className="text-start">
                  <span className="text-center relative">
                    <span className="2xl:text-[60px] xl:text-[60px] lg:text-[60px] md:text-[36px] text-[32px] font-semibold text-black">
                      The Work Revolution in One Digital Platform
                    </span>
                    <span className="absolute right-0">
                      <img src={"../assets/heading-vector.png"}></img>
                    </span>
                  </span>
                </div>
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] text-[16px] text-[#5C5C5C] 2xl:mt-[40px] xl:mt-[40px] lg:mt-[40px] md:mt-[30px] mt-[20px] 2xl:leading-[50px] xl:leading-[50px] lg:leading-[50px] md:leading-[30px] leading-[31px]">
                  Managing a project effectively requires seamless collaboration
                  clear communication, and easy access to essential tools.
                </p>
                <div className="2xl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[35px] mt-[20px] relative">
                  <form>
                    <input
                      className="[box-shadow:2px_3px_26px_0px_#2780C130] 2xl:p-[22px] xl:p-[22px] lg:p-[22px] md:p-[16px] p-[10px] rounded-[16px] w-[100%] focus:outline-none"
                      type="text"
                      name="search"
                      placeholder="Enter your email.."
                    />
                    <button className="bg-[linear-gradient(98.66deg,_#0D62A2_-5.19%,_#3F9BDF_53.88%,_#0D62A1_131.31%)] 2xl:px-[23px] xl:px-[23px] lg:px-[23px] px-[15px] 2xl:py-[15px] xl:py-[15px] lg:py-[15px] md:py-[11px] py-[7px] font-semibold rounded-[12px] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] text-[14px] text-white absolute right-[10px] top-[5px]">
                      Get Start For Free
                    </button>
                  </form>
                </div>
                <div className="flex items-center 2xl:gap-36 xl:gap-36 lg:gap-36 md:gap-36 gap-32 2xl:mt-[60px] xl:mt-[60px] lg:mt-[60px] md:mt-[50px] mt-[20px]">
                  <div className="2xl:w-[50px] xl:w-[50px] lg:w-[50px] md:w-[50px] w-[40px] 2xl:h-[50px] xl:h-[50px] lg:h-[50px] md:h-[50px] h-[40px] object-cover flex relative ">
                    <img src="../assets/Ellipse 85.png"></img>
                    <img
                      className="absolute 2xl:left-[30px] xl:left-[30px] lg:left-[30px] md:left-[30px] left-[20px]"
                      src="../assets/Ellipse 85.png"
                    ></img>
                    <img
                      className="absolute 2xl:left-[60px] xl:left-[60px] lg:left-[60px] md:left-[60px] left-[40px]"
                      src="../assets/Ellipse 85.png"
                    ></img>
                    <img
                      className="absolute 2xl:left-[90px] xl:left-[90px] lg:left-[90px] md:left-[90px] left-[60px]"
                      src="../assets/Ellipse 85.png"
                    ></img>
                    <img
                      className="absolute 2xl:left-[120px] xl:left-[120px] lg:left-[120px] md:left-[120px] left-[80px]"
                      src="../assets/Ellipse 85.png"
                    ></img>
                  </div>
                  <div>
                    <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] text-[14px] font-semibold">
                      120+ employees
                    </p>
                    <div className="flex items-center gap-2">
                      <img className="" src="../assets/Star 1.png"></img>
                      <p className="text-[#2E3E5C] font-medium 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[12px]">
                        5.0 (3.1K Reviews)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                className="2xl:block xl:block lg:block md:block hidden"
                src="../assets/heroside-img.png"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          className="absolute left-0 bottom-0"
          src="../assets/Intersect (1).png"
        ></img>
        <img
          className="absolute right-0 bottom-0"
          src="../assets/Intersect.png"
        ></img>
      </div>
    </div>
  );
}

export default Herosection;
