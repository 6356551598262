import React from "react";
import { Heading } from "../project";
import { dateFormatter } from "../../helper/formatDate";
import { CustomAssignees, CustomButton } from "../common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Roles } from "../../constant/Constant";

const TaskDetails = ({ taskDetails }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="p-4">
      <div className="relative font-semibold bg-gradient-custom-light-blue rounded-lg p-2 border">
        <h1 className="w-[88%] text-sm md:text-base text-justify ">
          {taskDetails?.title}
        </h1>
        {user?.roleId !== Roles?.employee && (
          <CustomButton
            title={"Edit Task"}
            buttonType={"button"}
            onClick={() => {
              navigate("/task/create", {
                state: { task: taskDetails },
              });
            }}
            classname="absolute right-0 top-1 underline text-[#2E6790] px-5 py-1 rounded-md"
          />
        )}
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4 gap-y-10 my-5">
        <Heading label="Task ID" value={taskDetails?.taskId} />
        <Heading
          label="Project Name"
          value={taskDetails?.project?.project_name}
        />
        <Heading
          label="Start Date"
          value={
            taskDetails?.start_date
              ? dateFormatter(taskDetails?.start_date)
              : "--"
          }
        />
        <Heading
          label="End Date"
          value={
            taskDetails?.due_date ? dateFormatter(taskDetails?.due_date) : "--"
          }
        />
        <Heading label="Status" value={taskDetails?.status} />
        <Heading label="Priority" value={taskDetails?.priority} />
        <Heading
          label="Assignees"
          value={<CustomAssignees assignees={taskDetails?.assign_to} />}
        />
        <Heading
          label="Completed ON"
          value={
            taskDetails?.completed_on
              ? dateFormatter(taskDetails?.completed_on)
              : "--"
          }
        />
        <Heading
          label="Estimated Time"
          value={taskDetails?.estimated_time ?? "--"}
        />
        <Heading
          label="Hours Logged"
          value={taskDetails?.hours_logged ?? "--"}
        />
      </div>
      <div className="w-full text-justify">
        <h3 className="text-[#757575]">Description</h3>
        <span className="text-[15px]">{taskDetails?.description}</span>
      </div>
      <div className="w-full bg-[#F4F9FC] p-2 flex justify-around items-center gap-4 rounded-md my-2 md:my-4">
        <Heading
          label="Created At"
          value={
            taskDetails?.createdAt
              ? dateFormatter(taskDetails?.createdAt)
              : "--"
          }
        />
        <Heading
          label="Last Updated"
          value={
            taskDetails?.updatedAt
              ? dateFormatter(taskDetails?.updatedAt)
              : "--"
          }
        />
        <Heading
          label="Created By"
          value={
            taskDetails?.assign_by?._id === user?._id
              ? "You"
              : taskDetails?.assign_by?.user_name
          }
        />
      </div>
    </div>
  );
};

export default TaskDetails;
