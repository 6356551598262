import React from 'react'
import { IoCall, IoLocationOutline, IoMailOutline } from 'react-icons/io5'

function Footer() {
  return (
    <div className='' style={{ backgroundImage: "url('../assets/footer.png')" }}>
      <div className='container mx-auto flex justify-between border-b border-[#485763] py-[60px] px-5'>
        <div>
          <img className='2xl:w-auto xl:w-auto lg:w-auto md-w-auto ' src='../assets/footer-logo.png'></img>
        </div>
        <div className='flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row flex-col items-center gap-[10px]'>
          <div className='flex items-center gap-2 border-r pr-10 border-[#485763] h-[30px]'>
          <IoCall className='text-white' />
          <p className='text-white'>
          +91 8569335555
          </p>
          </div>
          <div className='flex items-center gap-2 border-r pr-10 border-[#485763] h-[30px]'>
          <IoMailOutline className='text-white ' />
          <p className='text-white'>
          Info@meda.com
          </p>
          </div>
          <div className='flex items-center gap-2 border-r pr-10 border-[#485763] h-[30px]'>
          <IoLocationOutline className='text-white' />
          <p className='text-white'>
          +91 8569335555
          </p>
          </div>
        </div>
      </div>
    <div className='text-white text-center py-[25px]'>
    <p>
      @2024 Project Management System. All Right Reserved.
      </p>
    </div>
    </div>
  )
}

export default Footer