import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { designationEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchDesignation = createAsyncThunk(
  "fetchDesignation",
  async ({ token, currentPage, itemsPerPage }) => {
    let response;
    if (currentPage && itemsPerPage) {
      response = await apiConnector(
        "GET",
        designationEndPoints?.DESIGNATION_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_number: currentPage,
          page_size: itemsPerPage,
        }
      );
    } else {
      response = await apiConnector(
        "GET",
        designationEndPoints?.DESIGNATION_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    }
    return response.data;
  }
);

// Create a slice
const designationSlice = createSlice({
  name: "designation",
  initialState: {
    designationData: [],
    designationLoading: false,
    designationError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignation.pending, (state) => {
        state.designationLoading = true;
        state.designationData = null;
        state.designationError = null;
      })
      .addCase(fetchDesignation.fulfilled, (state, action) => {
        state.designationData = action.payload;
        state.designationLoading = false;
        state.designationError = null;
      })
      .addCase(fetchDesignation.rejected, (state, action) => {
        state.designationError = action.error.message;
        state.designationLoading = false;
        state.designationData = null;
      });
  },
});

export default designationSlice.reducer;
