import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { officeLocationEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchOfficeLocation = createAsyncThunk(
  "fetchOfficeLocation",
  async ({ token, page_number, page_size }) => {
    let response;
    if (page_number && page_size) {
      response = await apiConnector(
        "GET",
        officeLocationEndPoints?.OFFICE_LOCATION_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_number: page_number,
          page_size: page_size,
        }
      );
    } else {
      response = await apiConnector(
        "GET",
        officeLocationEndPoints?.OFFICE_LOCATION_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    }
    return response.data;
  }
);

// Create a slice
const officeLocationSlice = createSlice({
  name: "officeLocation",
  initialState: {
    officeLocationData: [],
    officeLocationLoading: false,
    officeLocationError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOfficeLocation.pending, (state) => {
        state.officeLocationLoading = true;
        state.officeLocationData = null;
        state.officeLocationError = null;
      })
      .addCase(fetchOfficeLocation.fulfilled, (state, action) => {
        state.officeLocationData = action.payload;
        state.officeLocationLoading = false;
        state.officeLocationError = null;
      })
      .addCase(fetchOfficeLocation.rejected, (state, action) => {
        state.officeLocationError = action.error.message;
        state.officeLocationLoading = false;
        state.officeLocationData = null;
      });
  },
});

export default officeLocationSlice.reducer;
