import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { MdOutlineVisibility } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const CustomInput = ({
  name = "",
  label = "",
  inputType = "",
  control,
  required = false,
  error,
  placeholder = "",
  labelClassName = "block text-custom-black",
  classname = "",
  isEditable = false,
  max = "",
  min = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return inputType === "text-area" ? (
    <>
      <label
        htmlFor={name}
        className={`font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) => (
            <textarea
              name={name}
              required={required}
              rows={3}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={isEditable}
              className={`border px-3 py-2 mt-2 outline-none w-full rounded-md resize-none text-justify focus:border-custom-blue ${
                isEditable ? "bg-gray-100 cursor-not-allowed" : ""
              } ${classname}`}
            />
          )}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </>
  ) : (
    <>
      <label
        htmlFor={name}
        className={`font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="relative">
        {inputType === "password" ? (
          showPassword ? (
            <MdOutlineVisibility
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              className="absolute text-[#96A9B7] cursor-pointer right-2 top-4"
            />
          ) : (
            <RiEyeCloseLine
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              className="absolute text-[#96A9B7] cursor-pointer right-2 top-4"
            />
          )
        ) : (
          ""
        )}

        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              tabIndex={0}
              name={name}
              required={required}
              placeholder={placeholder}
              type={showPassword ? "text" : inputType}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={isEditable}
              max={max}
              min={min}
              className={`border px-3 py-2 mt-2 text-sm outline-none w-full rounded-md focus:border-custom-blue ${
                isEditable ? "bg-gray-100" : ""
              } ${classname}`}
            />
          )}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </>
  );
};

export default CustomInput;
