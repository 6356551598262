import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const getAllTeamTask = createAsyncThunk(
  "getAllTeamTask",
  async ({ token, currentPage, itemsPerPage, projectId }) => {
    let response;
    if (currentPage && itemsPerPage && projectId) {
      response = await apiConnector(
        "GET",
        taskEndPoints?.TEAM_TASK_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          projectId: projectId,
          page_size: itemsPerPage,
          page_number: currentPage,
        }
      );
    } else {
      response = await apiConnector(
        "GET",
        taskEndPoints?.TASK_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          projectId: projectId,
        }
      );
    }
    return response.data;
  }
);
export const getAllPersonalTask = createAsyncThunk(
  "getAllPersonalTask",
  async ({ token, currentPage, itemsPerPage }) => {
    let response;
    if (currentPage && itemsPerPage) {
      response = await apiConnector(
        "GET",
        taskEndPoints?.TASK_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_size: itemsPerPage,
          page_number: currentPage,
        }
      );
    } else {
      response = await apiConnector("GET", taskEndPoints?.TASK_API, null, {
        Authorization: `Bearer ${token}`,
      });
    }
    return response.data;
  }
);

// Create a slice
const taskSlice = createSlice({
  name: "task",
  initialState: {
    teamTaskData: [],
    personalTaskData: [],
    taskLoading: false,
    taskError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTeamTask.pending, (state) => {
        state.taskLoading = true;
        state.teamTaskData = null;
        state.taskError = null;
      })
      .addCase(getAllTeamTask.fulfilled, (state, action) => {
        state.teamTaskData = action.payload;
        state.taskLoading = false;
        state.taskError = null;
      })
      .addCase(getAllTeamTask.rejected, (state, action) => {
        state.taskError = action.error.message;
        state.taskLoading = false;
        state.teamTaskData = null;
      })
      .addCase(getAllPersonalTask.pending, (state) => {
        state.taskLoading = true;
        state.personalTaskData = null;
        state.taskError = null;
      })
      .addCase(getAllPersonalTask.fulfilled, (state, action) => {
        state.personalTaskData = action.payload;
        state.taskLoading = false;
        state.taskError = null;
      })
      .addCase(getAllPersonalTask.rejected, (state, action) => {
        state.taskError = action.error.message;
        state.taskLoading = false;
        state.personalTaskData = null;
      });
  },
});

export default taskSlice.reducer;
