import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export function dateFormatter(
  dateString,
  options = { format: "MMM DD, YYYY", includeTimeForToday: true }
) {
  const date = dayjs(dateString).tz("Asia/Kolkata");

  const { format, includeTimeForToday } = options;

  if (date.isToday()) {
    return includeTimeForToday
      ? `Today ${date.format("HH:mm A")}`
      : `${date.format("HH:mm A")}`;
  } else if (date.isYesterday()) {
    return includeTimeForToday
      ? `Yesterday ${date.format("HH:mm A")}`
      : "Yesterday";
  }

  return date.format(format);
}

export const groupByDate = (items) => {
  if (!items || items.length === 0) return {};

  return items.reduce((acc, note) => {
    const noteDate = dayjs(note.createdAt);
    let displayDate;

    if (noteDate.isToday()) {
      displayDate = "Today";
    } else if (noteDate.isYesterday()) {
      displayDate = "Yesterday";
    } else {
      displayDate = noteDate.format("MMM DD, YYYY");
    }

    if (!acc[displayDate]) {
      acc[displayDate] = [];
    }
    acc[displayDate].push(note);
    return acc;
  }, {});
};
