import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomButton, CustomInput, CustomSwitch } from "../common";
import { CiLocationOn, CiUser } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import * as yup from "yup";
import { companyEndPoints } from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../networking/ApiConnector";
import { toast } from "react-toastify";
import { FaRegBuilding } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiError } from "../../helper/ApiError";
import { fetchCompanyDetails } from "../../redux/slice/companySlice";
import { isAction } from "@reduxjs/toolkit";

const CreateAdminValidation = yup.object().shape({
  company_name: yup.string().required("Company Name is required"),
  company_email: yup
    .string()
    .email("Invalid Email")
    .required("Company Email is required"),
  company_contact_no: yup
    .string()
    .matches(/^[6-9]\d{9}$/, "Please enter valid Mobile number")
    .required("Contact Number is required"),
  user_name: yup.string().required("Username is required"),
  email: yup.string().email("Invalid Email").required("Email is required"),
  mobile_number: yup
    .string()
    .matches(/^[6-9]\d{9}$/, "Please enter valid Mobile number")
    .required("Mobile Number is required"),
});

const CreateCompanyForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, loading } = useSelector((state) => state.auth);
  const companyId = location?.state?.companyId;
  const [isEditMode, setIsEditMode] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isCompanyActive, setIsCompanyActive] = useState(true);
  const getCompanyDetails = async () => {
    const response = await dispatch(
      fetchCompanyDetails({ token, companyId })
    ).unwrap();

    if (response?.data) {
      setCompanyDetails(response?.data?.company_details);
      setIsCompanyActive(response?.data?.company_details?.isActive);
    }
  };
  useEffect(() => {
    getCompanyDetails();
  }, [companyId]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAdminValidation),
  });

  useEffect(() => {
    if (companyId && companyDetails) {
      setIsEditMode(true);
      reset({
        company_name: companyDetails?.company_name ?? undefined,
        company_email: companyDetails?.company_email ?? undefined,
        company_contact_no: companyDetails?.company_contact_no ?? undefined,
        company_address: companyDetails?.company_address ?? undefined,
        user_name: companyDetails?.admin?.[0]?.user_name ?? undefined,
        email: companyDetails?.admin?.[0]?.actual_email ?? undefined,
        mobile_number:
          companyDetails?.admin?.[0]?.actual_mobile_number ?? undefined,
        department_limit: companyDetails?.department_limit ?? undefined,
        designation_limit: companyDetails?.designation_limit ?? undefined,
        office_location_limit:
          companyDetails?.office_location_limit ?? undefined,
      });
    }
  }, [companyId, companyDetails]);

  const getUpdatedFields = (companyDetails, data) => {
    let updatedFields = {};

    Object.keys(companyDetails).forEach((key) => {
      if (companyDetails[key] != data[key]) {
        updatedFields[key] = data[key];
      }
    });

    return updatedFields;
  };
  const onSubmitHandler = async (data) => {
    console.log(companyDetails, data, "data");
    let payload = isEditMode
      ? {
          ...getUpdatedFields(companyDetails, data),
          isActive:
            companyDetails?.isActive !== isCompanyActive
              ? isCompanyActive
              : undefined,
        }
      : data;
    console.log(payload, "payload");

    dispatch(setLoading(true));
    try {
      let api_Method = isEditMode ? "PUT" : "POST";
      let api_Url = isEditMode
        ? `${companyEndPoints?.COMPANY_API}/${companyId}`
        : companyEndPoints?.COMPANY_API;

      const company_response = await apiConnector(
        api_Method,
        api_Url,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(`${company_response?.data?.message}`);
      navigate("/company");
      reset();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className=" w-full border bg-custom-white rounded-md">
      <form
        className="w-full p-6"
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="">
          <h1 className="text-xl font-bold my-4">
            {isEditMode ? "Edit Company" : "Add Company"}
          </h1>
        </div>
        <div className="">
          <h2 className="text-lg font-semibold my-4">{`Company's Information`}</h2>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div className="my-4">
            <CustomInput
              name="company_name"
              label="Comapny's Name"
              inputType="text"
              placeholder="Company"
              control={control}
              required={true}
              error={errors?.company_name?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="company_email"
              label="Company's Email"
              inputType="text"
              placeholder="info@company.com"
              control={control}
              required={true}
              error={errors?.company_email?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="company_contact_no"
              label="Company's Contact"
              inputType="number"
              placeholder="99XXXXXXXX"
              control={control}
              required={true}
              error={errors?.company_contact_no?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="department_limit"
              label="Department Limit"
              inputType="number"
              placeholder="10"
              control={control}
              error={errors?.department_limit?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="designation_limit"
              label="Designation Limit"
              inputType="number"
              placeholder="10"
              control={control}
              error={errors?.designation_limit?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="office_location_limit"
              label="Office Location Limit"
              inputType="number"
              placeholder="10"
              control={control}
              error={errors?.office_location_limit?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="company_address"
              label="Company's Address"
              inputType="text-area"
              placeholder="Jaipur"
              control={control}
              error={errors?.company_address?.message}
            />
          </div>
          {isEditMode && (
            <div className="my-4">
              <label className="font-semibold text-sm text-slate-600">
                Company's Status
              </label>
              <div className="mt-2">
                <CustomSwitch
                  isActive={isCompanyActive}
                  handleStatus={(status) => setIsCompanyActive(status)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="">
          <h2 className="text-lg font-semibold my-4">{`Admin's Information`}</h2>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div className="my-4">
            <CustomInput
              name="user_name"
              label="Name"
              inputType="text"
              placeholder="John Deo"
              control={control}
              required={true}
              error={errors?.user_name?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="email"
              label="Email"
              inputType="text"
              placeholder="John.doe@gamil.com"
              control={control}
              required={true}
              error={errors?.email?.message}
            />
          </div>
          <div className="my-4">
            <CustomInput
              name="mobile_number"
              label="Mobile Number"
              inputType="number"
              placeholder="89XXXXXXXX"
              control={control}
              required={true}
              error={errors?.mobile_number?.message}
            />
          </div>
        </div>

        <div className="flex justify-center items-center gap-5 my-10">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => {
              reset();
              navigate(-1);
            }}
            classname="text-custom-black border px-10 py-2 rounded-full"
          />
          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            buttonType="submit"
            classname="bg-gradient-custom text-custom-white px-10 py-2 rounded-full"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCompanyForm;
