import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css"; // import Quill's snow theme
import { IoMdSend } from "react-icons/io";
import { setLoading } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { commentEndPoints } from "../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiError } from "../../helper/ApiError";

const TextEditor = ({ taskId, fetchComments, editComment, setEditComment }) => {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState("");
  const quillRef = useRef(null);
  const quillInstance = useRef(null);

  useEffect(() => {
    // Initialize Quill editor
    if (!quillInstance?.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["blockquote", "code-block"],
            ["link", "image"],
            [{ align: [] }],
            ["clean"],
          ],
        },
        formats: [
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "list",
          "script",
          "color",
          "background",
          "blockquote",
          "code-block",
          "link",
          "image",
          "align",
        ],
      });
    }
    // Pre-fill the editor if content is provided
    if (editComment?.content && quillInstance.current) {
      quillInstance.current.root.innerHTML = editComment?.content;
      setCommentText(editComment?.content.replace(/<p><br><\/p>/g, "").trim());
    }
    // Handle content changes
    quillInstance.current.on("text-change", () => {
      const quillContent = quillInstance.current.root.innerHTML;
      const cleanContent = quillContent.replace(/<p><br><\/p>/g, "").trim();
      setCommentText(cleanContent);
    });
  }, [editComment]);

  const handleSubmit = async () => {
    const htmlContent = quillInstance.current.root.innerHTML;

    // Clean unwanted empty paragraphs
    const cleanedContent = htmlContent.replace(/<p><br><\/p>/g, "").trim();

    if (cleanedContent) {
      dispatch(setLoading(true));
      try {
        const apiMethod = editComment ? "PUT" : "POST";
        const apiUrl = editComment
          ? `${commentEndPoints?.COMMENT_API}/${editComment?._id}`
          : `${commentEndPoints?.COMMENT_API}/${taskId}`;
        const comment_response = await apiConnector(
          apiMethod,
          apiUrl,
          { content: cleanedContent },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        toast.success(`${comment_response?.data?.message}`);
        if (fetchComments) fetchComments();
        quillInstance.current.root.innerHTML = ""; // Clear editor after success
        setCommentText(""); // Reset state
      } catch (error) {
        const errorMessage = ApiError(error);
        toast.error(`${errorMessage}`);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  return (
    <div className="relative">
      {/* Quill Editor */}
      <div
        ref={quillRef}
        className="w-full  border-l border-r border-b rounded-b-lg shadow border-gray-300 bg-[#f5ebeb] bg-opacity-20 text-custom-black"
        style={{ height: "200px" }}
      />
      <div
        className={`flex justify-center items-center gap-3 absolute bottom-2 right-2`}
      >
        <button
          type="button"
          className={` border px-3 py-0.5 rounded-md text-custom-white font-semibold ${
            commentText?.length > 0
              ? "bg-custom-black bg-opacity-100 cursor-pointer"
              : "bg-gray-300 bg-opacity-30 cursor-text"
          }`}
          onClick={() => {
            quillInstance.current.root.innerHTML = "";
            setEditComment(null);
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className={` w-7 h-7 rounded-full flex justify-center items-center text-custom-white ${
            commentText?.length > 0
              ? "bg-custom-black bg-opacity-100 cursor-pointer"
              : "bg-gray-300 bg-opacity-30 cursor-text"
          }`}
          onClick={handleSubmit}
        >
          <IoMdSend />
        </button>
      </div>
    </div>
  );
};

export default TextEditor;
