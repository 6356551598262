import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { projectEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const getAllProject = createAsyncThunk(
  "getAllProject",
  async ({ token, page_number, page_size }) => {
    let response;
    if (page_number && page_size) {
      response = await apiConnector(
        "GET",
        projectEndPoints?.PROJECT_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_size: page_size,
          page_number: page_number,
        }
      );
    } else {
      response = await apiConnector(
        "GET",
        projectEndPoints?.PROJECT_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    }
    return response?.data;
  }
);

// Create a slice
const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectData: [],
    projectLoading: false,
    projectError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProject.pending, (state) => {
        state.projectLoading = true;
        state.projectData = null;
        state.projectError = null;
      })
      .addCase(getAllProject.fulfilled, (state, action) => {
        state.projectData = action.payload;
        state.projectLoading = false;
        state.projectError = null;
      })
      .addCase(getAllProject.rejected, (state, action) => {
        state.projectError = action.error.message;
        state.projectLoading = false;
        state.projectData = null;
      });
  },
});

export default projectSlice.reducer;
